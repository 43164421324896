"use client";
import { useEffect, useMemo, useState } from "react";
import { useDataLayerContext } from "./DataLayerContextProvider";
import { useSessionContext } from "@/lib/auth/SessionContextProvider";
import { DataLayerEvents } from "./DataLayerContextProvider";
import {
	getUserCompanyData,
	getUserData,
} from "@/lib/bigcommerce-b2b/api/account";
import { CompanyDetailsData, UserData } from "@/lib/bigcommerce-b2b/types";
interface Props {
	pageType: PageType;
	userId: number | undefined;
	companyName: string | undefined;
}

type PageType =
	| "page"
	| "product"
	| "cart"
	| "checkout"
	| "company"
	| "informational"
	| "regional"
	| "blogs"
	| "news";

export default function PageViewEvent({
	pageType,
	userId,
	companyName,
}: Props) {
	const { push } = useDataLayerContext();
	useEffect(() => {
		push({
			event: DataLayerEvents.PAGE_VIEW,
			// currencyCode: "EUR",
			userId: userId !== 0 ? userId : null,
			companyName: companyName ?? null,
			// accountNumber: "12345678",
			pageType,
		});
	}, []);

	return null;
}
