"use client";

import { createContext, useContext, useEffect } from "react";

interface ContextProps {
	push: (event: any) => void;
}

interface Props {
	children: React.ReactNode;
}

const Context = createContext({} as ContextProps);

export const DataLayerProvider = ({ children }: Props) => {
	const GTM_ID = process.env.GTM_ID;
	const push = (event: any) => {
		const dataLayer = (window as any).dataLayer;

		if (!dataLayer) {
			console.info("DataLayer not found");
			return;
		}

		dataLayer.push(event);
	};

	const value = {
		push,
	};

	useEffect(() => {
		const pushFormSubmissionEvent = (event: MessageEvent<any>) => {
			if (
				event.data.type === "hsFormCallback" &&
				event.data.eventName === "onFormSubmitted"
			) {
				push({
					event: DataLayerEvents.FORM_SUBMISSION,
					formType: getFormName(event.data.id),
				});
			}
		};
		window.addEventListener("message", (e) => pushFormSubmissionEvent(e));
		return () => window.removeEventListener("message", pushFormSubmissionEvent);
	}, []);

	return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useDataLayerContext = () => {
	const { push } = useContext<ContextProps>(Context);

	// if (!context) {
	// 	throw new Error(
	// 		"useDataLayerContext must be used within a DataLayerProvider",
	// 	);
	// }

	const formSubmission = (formType: string) => {
		push({
			event: DataLayerEvents.FORM_SUBMISSION,
			formType,
		});
	};

	return { push, formSubmission };
};

// "as const" syntax is better and more reliable than enums
export const DataLayerEvents = {
	PAGE_VIEW: "page_view",
	VIEW_ITEM_LIST: "view_item_list",
	SELECT_ITEM: "select_item",
	VIEW_ITEM: "view_item",
	CART_OBJECT: "cart_object",
	ADD_TO_CART: "add_to_cart",
	ADD_TO_WISHLIST: "add_to_wishlist",
	REMOVE_FROM_WISHLIST: "remove_from_wishlist",
	VIEW_CART: "view_cart",
	REMOVE_FROM_CART: "remove_from_cart",
	BEGIN_CHECKOUT: "begin_checkout",
	ADD_SHIPPING_INFO: "add_shipping_info",
	ADD_PAYMENT_INFO: "add_payment_info",
	PURCHASE: "purchase",
	REGISTRATION: "registration",
	FORM_SUBMISSION: "form_submission",
	ACCOUNT_SUCCESS: "login_success",
	ACCOUNT_FAIL: "login_fail",
	LOGIN: "login",
	LOGOUT: "logout",
} as const;

type EventValues<T> = T[keyof T];
export type DEvents = EventValues<typeof DataLayerEvents>;

const formIdToFormName: Record<string, string> = {
	"752945a7-ec1a-43dc-92d8-659b19c0ee65": "contact_form",
	"477b33ec-5eed-45b4-aa31-3e5808530706": "footer_email_sign_up",
};

const getFormName = (formId: string) => {
	return formIdToFormName[formId] ?? formId;
};
